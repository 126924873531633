import { I18N } from "aurelia-i18n";
import { TransportService } from "services/client/transport-service";
import { WarehouseService } from "services/client/warehouse-service";
import { UserService } from "services/identity/user-service";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { CartService } from "services/cart/cart-service";
import { ICart } from "domain/Cart/ICart";
import { LogManager, autoinject, PLATFORM } from "aurelia-framework";
import {
  RouterConfiguration,
  Router,
  NavigationInstruction,
  Next,
  Redirect,
  PipelineResult,
} from "aurelia-router";
import { AppConfig } from "app-config";
import numeral from "numeral";
import { EventAggregator } from "aurelia-event-aggregator";
import { NotAuthorizedRedirect } from "utils/classes/NotAuthorizedRedirect";
import { AuthInterceptor } from "utils/classes/AuthInterceptor";

export const log = LogManager.getLogger("app.main-router");

@autoinject
export class MainRouter {
  router: Router;

  cartPreview: ICart;
  cartTotal = 0;

  currentYear = new Date().getFullYear();
  currentLocaleClass = "flag-icon-ee";

  constructor(
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator,
    private cartService: CartService,
    private userService: UserService,
    private warehouseService: WarehouseService,
    private transportService: TransportService,
    private i18n: I18N,
    private authInterceptor: AuthInterceptor,
  ) {
    this.authInterceptor = authInterceptor;

    numeral.register("locale", "et", {
      delimiters: { thousands: " ", decimal: "," },
      abbreviations: {
        thousand: " tuh",
        million: " mln",
        billion: " mld",
        trillion: " trl",
      },
      ordinal: () => ".",
      currency: { symbol: "€" },
    });
    numeral.locale("et");

    this.eventAggregator.subscribe(TKEvent.localeChanged, (locale: string) => {
      // this.i18n.getLocale() returns wrong data here for some reason
      this.currentLocaleClass = Utils.getLocaleClass(locale);
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateTotal();
    });
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadNames();
    });
    this.eventAggregator.subscribe(
      "router:navigation:success",
      (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        const currentRoute = event.instruction.config.name;
        if (currentRoute === undefined || currentRoute === null) return;

        // Run only when user is logged in
        if (this.appConfig.isLoggedIn) this.updateTotal();
      },
    );
  }

  // called only once: when the site is opened or refreshed
  activate() {
    // If user is already logged in (refreshed the page)
    if (this.appConfig.isLoggedIn) {
      this.updateTotal();
      this.loadSettings();
    }

    this.warningForOldBrowsers();
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;

    this.router.transformTitle = title => this.i18n.tr("general.title", { pageTitle: title });

    config.addAuthorizeStep(AuthorizeStep);

    config.options.pushState = true;
    config.options.root = "/";

    config.map([
      {
        route: "",
        name: "home",
        title: this.i18n.tr("pages.home.title"),
        moduleId: PLATFORM.moduleName("pages/home"),
        settings: { auth: true },
      },

      {
        route: "error",
        name: "errorIndex",
        title: this.i18n.tr("pages.error.title"),
        moduleId: PLATFORM.moduleName("pages/error/index"),
        settings: { auth: false },
      },
      {
        route: "privacy",
        name: "privacyIndex",
        title: this.i18n.tr("pages.privacy.title"),
        moduleId: PLATFORM.moduleName("pages/privacy/index"),
        settings: { auth: false },
      },

      {
        route: "search/:query/:page?/:type?",
        name: "searchResult",
        title: this.i18n.tr("pages.search.title"),
        moduleId: PLATFORM.moduleName("pages/search/index"),
        settings: { auth: true },
      },

      {
        route: "car/manufacturers",
        name: "carManufacturers",
        title: this.i18n.tr("pages.manufacturers.title"),
        moduleId: PLATFORM.moduleName("pages/car/manufacturers"),
        settings: { auth: true },
      },
      {
        route: "car/models/:id/:name/:query?",
        name: "carModels",
        title: this.i18n.tr("pages.models.title"),
        moduleId: PLATFORM.moduleName("pages/car/models"),
        settings: { auth: true },
      },
      {
        route: "car/vehicles/:query",
        name: "carVehiclesQuery",
        title: this.i18n.tr("pages.vehicles.title"),
        moduleId: PLATFORM.moduleName("pages/car/vehicles"),
        settings: { auth: true },
      },
      {
        route: "car/vehicles/:manuId/:modelId/:query?",
        name: "carVehicles",
        title: this.i18n.tr("pages.vehicles.title"),
        moduleId: PLATFORM.moduleName("pages/car/vehicles"),
        settings: { auth: true },
      },

      {
        route: "catalogue/list/:vehicleId/:query?",
        name: "catalogueList",
        title: this.i18n.tr("pages.car.title"),
        moduleId: PLATFORM.moduleName("pages/catalogue/list"),
        settings: { auth: true },
      },
      {
        route: "catalogue/products/:vehicleId/:catalogueId/:catalogueName/:page?",
        name: "catalogueProducts",
        title: this.i18n.tr("pages.catalogue.title"),
        moduleId: PLATFORM.moduleName("pages/catalogue/products"),
        settings: { auth: true },
      },

      {
        route: "category/manufacturers/:categoryId",
        name: "categoryManufacturers",
        title: this.i18n.tr("pages.manufacturers.title"),
        moduleId: PLATFORM.moduleName("pages/category/manufacturers"),
        settings: { auth: true },
      },
      {
        route: "category/models/:categoryId/:manufacturer",
        name: "categoryModels",
        title: this.i18n.tr("pages.models.title"),
        moduleId: PLATFORM.moduleName("pages/category/models"),
        settings: { auth: true },
      },
      {
        route: "category/vehicles/:categoryId/:manufacturer/:model",
        name: "categoryVehicles",
        title: this.i18n.tr("pages.vehicles.title"),
        moduleId: PLATFORM.moduleName("pages/category/vehicles"),
        settings: { auth: true },
      },
      {
        route: "category/products/:categoryId/:page/:vehicleId?",
        name: "categoryProducts",
        title: this.i18n.tr("pages.category.title"),
        moduleId: PLATFORM.moduleName("pages/category/products"),
        settings: { auth: true },
      },

      {
        route: "cart",
        name: "cartIndex",
        title: this.i18n.tr("pages.cart.title"),
        moduleId: PLATFORM.moduleName("pages/cart/index"),
        settings: { auth: true },
      },
      /* {
        route: 'cart/:id', name: 'cartInfo', title: 'Ostukorv',
        moduleId: PLATFORM.moduleName('pages/cart/info'), settings: { auth: true }
      },*/

      {
        route: "order/:page?",
        name: "orderIndex",
        title: this.i18n.tr("pages.orders.title"),
        moduleId: PLATFORM.moduleName("pages/order/index"),
        settings: { auth: true },
      },
      {
        route: "invoice/:page?",
        name: "invoiceIndex",
        title: this.i18n.tr("pages.invoices.title"),
        moduleId: PLATFORM.moduleName("pages/invoice/index"),
        settings: { auth: true },
      },

      {
        route: "return/:page?",
        name: "returnIndex",
        title: this.i18n.tr("pages.returns.title"),
        moduleId: PLATFORM.moduleName("pages/return/index"),
        settings: { auth: true },
      },

      {
        route: "contact",
        name: "contactIndex",
        title: this.i18n.tr("pages.contact.title"),
        moduleId: PLATFORM.moduleName("pages/contact/index"),
        settings: { auth: true },
      },

      {
        route: "user",
        name: "userAccount",
        title: this.i18n.tr("pages.user.settings.title"),
        moduleId: PLATFORM.moduleName("pages/user/index"),
        settings: { auth: true },
      },
      {
        route: "user/login",
        name: "userLogin",
        title: this.i18n.tr("pages.user.login.title"),
        moduleId: PLATFORM.moduleName("pages/user/login"),
        settings: { auth: false },
      },
      {
        route: "user/admin/:email/:token",
        name: "userAdmin",
        moduleId: PLATFORM.moduleName("pages/user/admin"),
        settings: { auth: false },
      },
      {
        route: "user/reset/:locale/:email/:token",
        name: "userReset",
        title: this.i18n.tr("pages.user.reset.title"),
        moduleId: PLATFORM.moduleName("pages/user/reset"),
        settings: { auth: false },
      },
    ]);

    config.fallbackRoute("error");
  }

  // #region LOAD

  loadSettings() {
    this.userService.getSettings().then(result => this.appConfig.setSettings(result));
  }

  loadNames() {
    this.setLocale(this.appConfig.userSettings.locale);

    const warehouseId = this.appConfig.contractSettings.contract.warehouseId;
    this.transportService.getWarehouseTransportOptions(warehouseId).then(result => {
      this.appConfig.setTransportOptions(result);
    });

    this.warehouseService.getWarehouses().then(result => {
      this.appConfig.setWarehouses(result);
    });
  }

  // #endregion

  // #region ACTIONS

  setLocale(locale: string) {
    Utils.setLocale(this.eventAggregator, this.i18n, locale);
  }

  changeLocale(locale: string) {
    this.setLocale(locale);
    this.saveLocale(locale);
  }

  saveLocale(locale: string) {
    if (!this.appConfig.userSettings) return;

    this.appConfig.userSettings.locale = locale;

    this.userService.updateSettings();
  }

  logout() {
    this.authInterceptor.logout();
  }

  // #endregion

  // #region HELPERS

  updateTotal() {
    this.cartService
      .fetchCurrent()
      .then(result => {
        this.cartPreview = result;

        this.cartTotal = 0;
        this.cartPreview.products.forEach(
          p => (this.cartTotal += (p.finalPrice ?? 0) * p.quantity),
        );
      })
      .catch(error => Utils.showErrorToast(log, Utils.getErrorMessage(error, this.i18n)));
  }

  // Use pure JS to display a warning for users of old browsers that aren't supported
  warningForOldBrowsers() {
    const browser = window.navigator.userAgent;

    document.title = this.i18n.tr("general.title", {
      pageTitle: this.i18n.tr("pages.home.title"),
    });

    // Internet Explorer
    if (browser.indexOf("Trident") > -1 || browser.indexOf("MSIE") > -1) {
      document.body.textContent = this.i18n.tr("general.errors.old-browser", {
        browser: this.getBrowser(),
      });
    }
  }

  // (c) https://stackoverflow.com/a/5918791/
  getBrowser() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    // IE
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }

    // Chromium
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

    return M.join(" ");
  }

  // #endregion
}

// (c) https://aurelia.io/docs/routing/configuration#pipelines
@autoinject
class AuthorizeStep {
  constructor(
    private appConfig: AppConfig,
    private navigate: NotAuthorizedRedirect,
    private authInterceptor: AuthInterceptor,
  ) {}

  run(instruction: NavigationInstruction, next: Next): Promise<any> {
    const requiresAuth = instruction.getAllInstructions().some(i => i.config.settings.auth);
    if (!requiresAuth) {
      return next();
    }

    if (!this.appConfig.isLoggedIn) {
      this.navigate.saveNextRoute(instruction);
      this.authInterceptor.logout();
      console.log("AuthorizeStep logging out");
      return next.cancel(new Redirect("user/login"));
    }

    const redirectUri = this.navigate.tryReturnTo();
    if (redirectUri) {
      return next.cancel(new Redirect(redirectUri));
    }

    return next();
  }
}
